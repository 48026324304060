import { Avatar } from '../Avatar/Avatar';
import { Col, Row, Typography } from 'antd';
import { CustomButton } from '../CustomButton/CustomButton';
import { CustomSlider } from '../CustomSlider/CustomSlider';
import { CustomSpinner } from '../CustomSpinner/CustomSpinner';
import { Edit } from 'src/common/icons';
import { Profile } from 'src/common/types';
import { getAge, getProfileNameLabel } from 'src/common/utils';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styles from './styles.module.scss';

const profileSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  infinite: true,
  swipeToSlide: true,
  dots: true,
  centermode: false,
  dotsClass: styles.SliderDot,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        infinite: true,
        variableWidth: true,
      },
    },
  ],
};

interface ProfilesSliderProps {
  onNext: (id: string) => void;
  onAvatarClick?: () => void;
  onEditClick?: () => void;
}

export const ProfilesSlider = ({ onNext, onAvatarClick, onEditClick }: ProfilesSliderProps) => {
  const { profileId } = useParams();
  const { data: profiles, isFetching } = useGetProfilesQuery();

  const defaultSlideNumber = useMemo(
    () => profiles?.findIndex((profile) => profile.idProfile === profileId),
    [profileId, profiles],
  );

  const handleNextProfileClick = (id: string) => {
    onNext(id);
  };

  return isFetching ? (
    <CustomSpinner />
  ) : profiles && profiles?.length > 0 ? (
    <CustomSlider
      sliderSettings={profileSliderSettings}
      onNextHandle={handleNextProfileClick}
      defaultSlideNumber={defaultSlideNumber}
    >
      {profiles?.map((profile: Profile) => (
        <Row className={styles.UserRow} key={profile.idProfile} id={profile.idProfile}>
          <Avatar
            idPhoto={profile.idPhoto}
            idProfile={profile.idProfile}
            className={`${styles.Avatar} ${onAvatarClick ? styles.AvatarEditable : ''}`}
            onClick={onAvatarClick}
            name={`${profile.surname} ${profile.name}`}
            classNameText={styles.AvatarText}
          />

          <Col>
            <Row className={styles.NameBlockWrapper}>
              <Typography>{getProfileNameLabel(profile)}</Typography>
              {onEditClick && (
                <CustomButton className={styles.EditButton} onClick={onEditClick}>
                  <Edit />
                </CustomButton>
              )}
            </Row>
            <Typography className={styles.GreySmallText}>{`${profile.surname} ${profile.name}${profile.middleName?.length > 0 ? ` ${profile.middleName}` : ''}, ${
              profile.birthDay ? `Возраст: ${getAge(profile.birthDay)}` : ''
            }`}</Typography>
          </Col>
        </Row>
      ))}
    </CustomSlider>
  ) : (
    <Col></Col>
  );
};
