import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { MilaMessage } from 'src/common/components/MilaMessage/MilaMessage';
import { Row, Typography } from 'antd';
import { ServiceRequestType } from 'src/common/types/enums';
import { SpecialtyIcon } from 'src/common/components/SpecialtyIcon/SpecialtyIcon';
import { Specialty } from 'src/common/types';
import { memo, useMemo, useState } from 'react';
import { specialtyColors } from 'src/common/contants/constatns';
import styles from './styles.module.scss';

interface ExtendedWhiteBlockProps {
  isFetching: boolean;
  specialties: Specialty[] | undefined;
  serviceType: ServiceRequestType;
}

export const maxCount = 15;

export const ExtendedWhiteBlock = memo(({ isFetching, specialties, serviceType }: ExtendedWhiteBlockProps) => {
  const [showAll, setShowAll] = useState(false);
  const handleShowAll = () => {
    setShowAll(true);
  };

  const slicedSpecialties = useMemo(
    () => (showAll ? specialties : specialties?.slice(0, maxCount)),
    [showAll, specialties],
  );

  return (
    <Row className={styles.WhiteBlock}>
      <Row className={styles.AgeRow}>
        <Typography className={styles.Title}>Популярные врачи</Typography>
        <AgeToggle mini />
      </Row>

      {isFetching ? (
        <CustomSpinner />
      ) : (
        <Row className={styles.SpecialtiesRow}>
          {slicedSpecialties?.map((specialty, index) => (
            <SpecialtyIcon
              key={specialty.idFerSpeciality}
              specialty={specialty}
              color={specialtyColors[index]}
              serviceType={serviceType}
            />
          ))}
        </Row>
      )}

      {specialties && specialties.length > maxCount &&
        (showAll ? (
          <MilaMessage
            title={'Смотрите больше вариантов'}
            text={'Для этого попробуйте изменить регион или включите поиск по всем регионам'}
          />
        ) : (
          <Typography className={styles.ShowAllText} onClick={handleShowAll}>
            Еще {specialties.length - maxCount > 0 ? specialties.length - maxCount : 0} специализаций
          </Typography>
        ))}
    </Row>
  );
});
