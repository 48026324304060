import { AgeToggle } from 'src/common/components/AgeToggle/AgeToggle';
import { AppointmentPlacemark } from '../MapModule/AppointmentPlacemark/AppointmentPlacemark';
import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { ChooseRegion } from '../../common/components/ChooseRegion/ChooseRegion';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomDatePicker } from 'src/common/components/CustomDatePicker/CustomDatePicker';
import { CustomSelector } from 'src/common/components/CustomSelector/CustomSelector';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { CustomSteps } from 'src/common/components/CustomSteps/CustomSteps';
import { Doctor, DoctorsArgs, MapState } from 'src/common/types';
import { DoctorCard } from './DoctorCard/DoctorCard';
import { Layout, Row, TimePicker, Typography } from 'antd';
import { MapWithPlacemarks } from '../../common/components/MapWithPlacemarks/MapWithPlacemarks';
import { RangePickerProps } from 'antd/es/date-picker';
import { filterDoctorsByAge } from 'src/common/utils';
import { setBranch } from 'src/common/redux/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { useGetAppoinmentClinicsQuery, useGetDoctorsQuery } from 'src/common/redux/api/apiPatientSlice';
import { useGetFerSpecialitiesQuery } from 'src/common/redux/api/apiDictionarySlice';
import { useParams, useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import styles from './styles.module.scss';

const items = [
  { label: `По дате приёма`, key: '1' },
  { label: `На карте`, key: '2' },
  { label: `По рейтингу`, key: '3' },
];

const format = 'HH:mm';

const DoctorChoice = () => {
  const { specialtyId } = useParams();
  const [searchParams] = useSearchParams();
  const branchId = useMemo(() => (searchParams.get('branchId') ? searchParams.get('branchId')! : ''), [searchParams]);
  const [activeTab, setActiveTab] = useState('1');
  const dispatch = useAppDispatch();
  const region = useAppSelector((state) => state.filter.region);
  const stateBranch = useAppSelector((state) => state.filter.branchId);
  const serviceRequestType = useAppSelector((state) => state.appointment.serviceRequestType);
  const isChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const [selectedBranchId, setSelectedBranchId] = useState<string>();
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [selectedStartTime, setSelectedStartTime] = useState<Dayjs | null>();
  const [selectedEndTime, setSelectedEndTime] = useState<Dayjs | null>();
  const [doctors, setDoctors] = useState<Doctor[]>();
  const [maxDoctorsShow, setMaxDoctorsShow] = useState<number>(5);
  const [placemark, setPlacemark] = useState();

  const args: DoctorsArgs = useMemo(
    () => ({
      dateFrom: selectedDate ? selectedDate.format() : dayjs().format(),
      dateTo: selectedDate ? selectedDate.add(1, 'days').format() : dayjs().add(2, 'weeks').format(),
      idFerSpeciality: specialtyId,
      serviceRequestType,
      practiceId: selectedBranchId,
      region,
    }),
    [selectedDate, specialtyId, serviceRequestType, selectedBranchId, region],
  );

  const { data: initialDoctors, isFetching } = useGetDoctorsQuery(args);

  const { data: specialities } = useGetFerSpecialitiesQuery();

  const { data: mapClinics, isLoading } = useGetAppoinmentClinicsQuery(
    { idFerSpecialty: specialtyId!, region: region ?? 'Все регионы' },
    { skip: !region || !specialtyId },
  );

  const clinicOptions = useMemo(
    () =>
      mapClinics?.length && mapClinics.length > 0
        ? mapClinics.map((clinic) => ({ label: clinic.shortName, value: clinic.idMedOrganization }))
        : [],
    [mapClinics],
  );

  const mapState = useMemo(() => {
    if (mapClinics && mapClinics.length > 0) {
      let x = 0,
        y = 0;
      mapClinics.forEach((branch) => {
        x += Number(branch.latitude);
        y += Number(branch.longitude);
      });
      return { center: [x / mapClinics.length, y / mapClinics.length], zoom: 15 } as MapState;
    }
    return;
  }, [mapClinics]);

  const addMaxDoctorsShow = () => setMaxDoctorsShow((prev) => prev + 5);

  useEffect(() => {
    if (stateBranch) {
      const clinic = clinicOptions.find(x => x.value === stateBranch);
      if (clinic) {
        setSelectedBranchId(stateBranch);
      }
    }
    if (branchId) {
      const clinic = clinicOptions.find(x => x.value === branchId);
      if (clinic) {
        setSelectedBranchId(branchId);
      }
    }
  }, [stateBranch, branchId, clinicOptions]);

  useEffect(() => {
    let filteredDoctors: Doctor[] = [];
    if (initialDoctors) {
      filteredDoctors = filterDoctorsByAge([...initialDoctors], isChildren, childrenAge, specialtyId);
    }

    if (activeTab === '3') {
      filteredDoctors = filteredDoctors.sort((a, b) => {
        if (a.reviewsRating === b.reviewsRating) {
          return b.reviewsCount - a.reviewsCount;
        } else {
          return b.reviewsRating - a.reviewsRating;
        }
      });
    }
    setDoctors(filteredDoctors);
  }, [activeTab, childrenAge, initialDoctors, isChildren, specialtyId]);

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const handleSelectChange = (branchId: string) => {
    setSelectedBranchId(branchId);
    dispatch(setBranch(branchId));
  };

  const handleDateSelect = (val: Dayjs | null) => {
    if (val) {
      setSelectedDate(val.startOf('d'));
      if (!selectedStartTime && val.startOf('d').isSame(dayjs().startOf('d'))) {
        setSelectedStartTime(dayjs().add(5, 'minute'));
      }
    }
  };

  const handleTimeSelect = (values: (Dayjs | null)[] | null) => {
    setSelectedStartTime(values && values[0]);
    setSelectedEndTime(values && values[1]);
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current.startOf('d') < dayjs().startOf('d');
  };

  const handleMapClick = (e: Event, branchId: string) => {
    e.preventDefault();
    setSelectedBranchId(branchId);
    dispatch(setBranch(branchId));
    setActiveTab('1');
  };

  return (
    <>
      <Row className={`${styles.Hat} ${activeTab !== '2' ? styles.FixedHat : ''}`}>
        <BackHeader title="Выберите врача" />

        <CustomSteps current={2} />

        <CustomCardTabs
          items={items}
          className={styles.Tabs}
          containerClassName={styles.TabsContainer}
          onChange={handleTabChange}
          activeKey={activeTab}
        />

        <Row className={styles.SpecialtyRow}>
          <Row className={styles.SubRow}>
            <Typography className={styles.SpecialtyName}>
              {specialities?.find((specialty) => specialty.code === specialtyId)?.name}
            </Typography>
            <AgeToggle mini containerClassName={styles.AgeToggle} />
          </Row>
          <ChooseRegion className={styles.RegionName} />
        </Row>

        {activeTab !== '2' && (
          <Row className={styles.FilterRow}>
            <CustomDatePicker
              inputName="date"
              value={selectedDate}
              onChange={handleDateSelect}
              className={styles.Picker}
              disabledDate={disabledDate}
              floatLabel="Дата приёма"
            />
            <TimePicker.RangePicker
              className={styles.Picker}
              format={format}
              placeholder={['00:00', '00:00']}
              onChange={handleTimeSelect}
              allowEmpty={[true, true]}
              separator={'–'}
            />
            <CustomSelector
              onChange={handleSelectChange}
              className={styles.Select}
              floatLabel={'Выберите филиал'}
              inputName={'selectedBranchId'}
              options={clinicOptions}
              value={selectedBranchId}
            />
          </Row>
        )}
      </Row>
      <Layout className={styles.Doctors}>
        {isFetching ? (
          <CustomSpinner />
        ) : activeTab === '2' ? (
          <MapWithPlacemarks mapState={mapState} placemark={placemark}>
            {mapClinics?.map((mapClinic) => (
              <AppointmentPlacemark
                branch={mapClinic}
                handleClick={handleMapClick}
                key={mapClinic.idMedOrganization}
                instanceRef={setPlacemark}
              />
            ))}
          </MapWithPlacemarks>
        ) : (
          <InfiniteScroll
            dataLength={doctors?.slice(0, maxDoctorsShow).length || 0}
            loader={<CustomSpinner />}
            next={addMaxDoctorsShow}
            hasMore={doctors ? doctors?.slice(0, maxDoctorsShow).length < doctors?.length : false}
            scrollableTarget="scrollableDiv"
          >
            {doctors?.slice(0, maxDoctorsShow).map((doctor) => (
              <Row key={doctor.idMilaDoctor} className={styles.DoctorRow}>
                <DoctorCard
                  doctor={doctor}
                  withSchedule
                  startTime={selectedStartTime}
                  endTime={selectedEndTime}
                  selectedDate={selectedDate}
                  preselectedSpecialtyId={specialtyId}
                  preselectedBranchId={branchId}
                />
              </Row>
            ))}
          </InfiniteScroll>
        )}
      </Layout>
    </>
  );
};

export default DoctorChoice;
