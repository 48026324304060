import { BackHeader } from '../../common/components/BackHeader/BackHeader';
import { ClinicForm } from './ClinicForm';
import { CustomCardTabs } from '../../common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Image, Row, Typography } from 'antd';
import { ItemsTabs } from '../../common/types';
import { MilaMessage } from '../../common/components/MilaMessage/MilaMessage';
import { SupportForm } from './SupportForm';
import { useGetProfilesQuery } from 'src/common/redux/api/apiPatientSlice';
import {
    useGetWhiteLabelInfoQuery
} from 'src/common/redux/api/apiWhiteLabelSlice';
import HelpPict from '../../assets/Help.png';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

const tabsItems: ItemsTabs[] = [
  {
    label: 'В службу техподдержки',
    key: 'support',
  },
  {
    label: 'В клинику',
    key: 'clinic',
  },
];

export const Help = () => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const { data: profiles, isFetching } = useGetProfilesQuery();
  const profileId = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile, [profiles]);
  const selfProfile = profiles?.find((profile) => profile.itSelf);

  const defaultCustomFieldsValues: { [key: string]: string } = selfProfile
    ? {
        firstName: selfProfile?.name,
        middleName: selfProfile?.middleName,
        lastName: selfProfile?.surname,
        email: selfProfile?.email,
        phone: selfProfile?.phone,
      }
    : {};

  const [selectedTab, setSelectedTab] = useState('support');

  const handleTabClick = useCallback((key: string) => setSelectedTab(key), []);

  const customFields = useMemo(
    () => ['subject', 'message', 'firstName', 'middleName', 'lastName', 'email', 'phone'],
    [],
  );

  const title = isWhiteLabelEnabled
    ? 'Кратко о том, когда писать в техподдержку, а когда - в клинику'
    : 'Два слова о том, когда писать в техподдержку, а когда - в клинику';

  const text = isWhiteLabelEnabled ? 'Чтобы быстрее получить ответ' : 'Чтобы я могла быстрее ответить';

  const hiddenText = isWhiteLabelEnabled ? (
    <>
      <Typography.Text>Чтобы быстрее получить ответ</Typography.Text>
      <Typography.Text>
        Пожалуйста, направьте заявку в <b>клинику</b>, где была оказана медицинская помощь, при возникновении вопросов,
        связанных с:
      </Typography.Text>
      <ol>
        <li>обнаружением некорректных или неполных данных в вашей медицинской карте;</li>
        <li>качеством оказания медицинской помощи персоналом клиники; качеством сервиса и вежливостью персонала;</li>
        <li>прочих вопросов, напрямую связанных с деятельностью медицинского учреждения.</li>
      </ol>{' '}
      <Typography.Text>
        Направьте заявку <b>в техподдержку</b> при возникновении:
      </Typography.Text>
      <ol>
        <li>сообщений об ошибках, вопросов по платежам;</li>
        <li>отзывов о качестве видеосвязи и работе чата;</li>
        <li>замечаний и предложений по работе сервиса.</li>
      </ol>
      <Typography.Text>Спасибо, что выражаете свое мнение, так мы становимся лучше!</Typography.Text>
    </>
  ) : (
    <>
      <Typography.Text>Чтобы я могла быстрее ответить</Typography.Text>
      <Typography.Text>
        Mila работает как платформа для обмена данными с медицинскими организациями и ни в коем случае не вмешивается в
        их деятельность.
      </Typography.Text>
      <Typography.Text>
        Поэтому я прошу вас обращаться <b>в клинику</b>, где была оказана медицинская помощь, при возникновении
        вопросов, связанных с:
      </Typography.Text>
      <ol>
        <li>обнаружением некорректных или неполных данных в вашей медицинской карте;</li>
        <li>качеством оказания медицинской помощи персоналом клиники; качеством сервиса и вежливостью персонала;</li>
        <li>прочих вопросов, напрямую связанных с деятельностью медицинского учреждения.</li>
      </ol>{' '}
      <Typography.Text>
        А <b>в техподдержку</b> Mila направлять:
      </Typography.Text>
      <ol>
        <li>сообщения об ошибках; вопросы по платежам;</li>
        <li>отзывы о качестве видеосвязи и работе чата;</li>
        <li>замечания и предложения по работе сервиса.</li>
      </ol>
      <Typography.Text>Спасибо, что выражаете свое мнение, так я смогу стать лучше 😇</Typography.Text>
    </>
  );

  const [customFieldsValues, setCustomFieldsValues] = useState<{ [key: string]: string }>(defaultCustomFieldsValues);

  useEffect(() => {
      if (selfProfile) {
          setCustomFieldsValues({
              firstName: selfProfile?.name,
              middleName: selfProfile?.middleName,
              lastName: selfProfile?.surname,
              email: selfProfile?.email,
              phone: selfProfile?.phone,
          });
      }
  }, [selfProfile]);
    
  const handleCustomFieldsValues = (values: { [key: string]: string }) => setCustomFieldsValues(values);

  return (
    <>
      <BackHeader title="Помощь" />
      <Row className={styles.Center}>
        <Image src={HelpPict} preview={false} />
      </Row>
      <MilaMessage title={title} text={text} hiddenText={hiddenText} />
      <Row>
        <CustomCardTabs items={tabsItems} onChange={handleTabClick} />
      </Row>
      {isFetching ? (
        <CustomSpinner />
      ) : (
        profileId &&
        (selectedTab === 'support' ? (
          <SupportForm
            idProfile={profileId}
            customFields={customFields}
            customFieldsValues={customFieldsValues}
            setCustomFieldsValues={handleCustomFieldsValues}
          />
        ) : (
          <ClinicForm
            idProfile={profileId}
            customFields={customFields}
            customFieldsValues={customFieldsValues}
            setCustomFieldsValues={handleCustomFieldsValues}
          />
        ))
      )}
    </>
  );
};
