import { AppointmentPaymentStatus, AppointmentStatus, NotificationType, PaymentStatusType } from '../types/enums';
import {
  AutocompleteOption,
  CalendarRecord,
  Doctor,
  NotificationEntity,
  Payment,
  Profile,
  RecordsWithDates,
  SpecialtyTag,
} from '../types';
import { MILA_ONLINE_LINK, N3_HEALTH_PATIENTS_LINK, avatarColors, clientId, redirectUrl } from '../contants/constatns';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import KeyCloakService from '../../keycloak';
import axios from 'axios';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const hexToRgb = (hex: any) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m: any, r: any, g: any, b: any) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x: any) => parseInt(x, 16));

export const groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
  array.reduce((acc, value, index, array) => {
    (acc[predicate(value, index, array)] ||= []).push(value);
    return acc;
  }, {} as { [key: string]: T[] });

export const getDateLabel = (date: Date) => {
  const today = new Date();
  if (date.getDate() === today.getDate()) {
    return 'Сегодня';
  }
  if (date.getDate() - 1 === today.getDate()) {
    return 'Завтра';
  }
  if (!date) {
    return 'По запросу';
  }
  return `с ${date.toLocaleDateString('ru-Ru', { month: 'long', day: 'numeric' })}`;
};

export const getDoctorsWithLabel = (arg: number) => {
  if (arg % 10 === 1) {
    return `${arg} врач`;
  }

  if ([2, 3, 4].includes(arg % 10)) {
    return `${arg} врача`;
  }

  return `${arg} врачей`;
};

export const mapRecordsWithDates = (type: string, records?: CalendarRecord[]) =>
  records && records.length > 0
    ? records
        .slice()
        .sort((a, b) =>
          type === 'upcoming'
            ? dayjs(a.created).isAfter(dayjs(b.created))
              ? 1
              : -1
            : !dayjs(a.created).isAfter(dayjs(b.created))
            ? 1
            : -1,
        )
        .map((record) => ({
          date: dayjs(record.created).format('dd, D MMMM YYYY'),
          originalDate: record.created,
          recordsData: [
            {
              ...record,
              isOnlineClinic: record.serviceDeliveryTypeName === 'Онлайн-консультация',
              isHomeClinic: record.paymentTypeName === 'На дому',
              isPaidAppointment: checkIsPaid(record.paymentStatus),
              isHasConclusion: false,
              clinicHref: '',
            },
          ],
        }))
    : [];

export const sortCalendarRecords = (records?: CalendarRecord[], reverse: boolean = false) => {
  let sortedRecords: CalendarRecord[] = [];
  if (records && records.length > 0) {
    sortedRecords = [...records].sort((a, b) => (dayjs(a.created).isAfter(dayjs(b.created)) ? 1 : -1));
  }
  if (reverse) {
    sortedRecords.reverse();
  }
  return sortedRecords;
};

export const groupRecordsByDate = (records: CalendarRecord[]): RecordsWithDates[] =>
  records.reduce((groups: RecordsWithDates[], record) => {
    const date = dayjs(record.created);
    const existing = groups.find((group: RecordsWithDates) => group.date.isSame(date, 'd'));
    if (existing) {
      const existingIndex = groups.indexOf(existing);
      groups[existingIndex].recordsData.push(record);
    } else {
      groups.push({ date: date, recordsData: [record] });
    }

    return groups;
  }, []);

export const sortAppointmentsByDates = (records?: CalendarRecord[]) =>
  records && records.length > 0
    ? records
        .slice()
        .sort((a, b) => (dayjs(a.created).isAfter(dayjs(b.created)) ? 1 : -1))
        .filter((record) => !record.isCancelled)
    : [];

export const capitalizeFirstLetter = (value: string | null | undefined): string => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
  return value || '';
};

export const getCalendarRecordDateLabel = (date: Date) => {
  if (dayjs(date).isToday()) {
    return `Сегодня, ${dayjs(date).format('D MMMM YYYY')}`;
  } else if (dayjs(date).isTomorrow()) {
    return `Завтра, ${dayjs(date).format('D MMMM YYYY')}`;
  } else {
    return capitalizeFirstLetter(dayjs(date).format('dd, D MMMM YYYY'));
  }
};

export const getInitials = (fio: string) => (fio ? `${fio?.split(' ')[0][0]}${fio?.split(' ')[1][0]}` : '');

export const getMinutesToNow = (date: Date | string) => dayjs(date).diff(dayjs(), 'minute');

export const getTmkMinutesLeftLabel = (date: Date | string) =>
  getMinutesToNow(date) > 60 && getMinutesToNow(date) < 120
    ? `1 час ${getMinutesToNow(date) - 60}`
    : `${getMinutesToNow(date)}`;

export const normalizeFile = (e: UploadChangeParam<UploadFile>) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export const getWaitingListCardDateLabel = (date: Date | string) =>
  `${dayjs(date).format('D MMMM')} в ${dayjs(date).format('HH:mm')}`;

export const getPromotionDateLabel = (startDate?: Date | string, endDate?: string) =>
  dayjs(startDate).format('M') === dayjs(endDate).format('M')
    ? `${dayjs(startDate).format('D')} - ${dayjs(endDate).format('D MMMM')}`
    : `${dayjs(startDate).format('D MMMM')} - ${dayjs(endDate).format('D MMMM')}`;

export const getSpecialtyName = (idFedSpecialty: string, doctorsSpecializations: SpecialtyTag[]) =>
  doctorsSpecializations?.find((doctorSpecialty) => doctorSpecialty.idFerSpeciality === idFedSpecialty)
    ?.specialityName || '';

export const prepareSearchParams = (params: Record<string, any>): URLSearchParams => {
  let query = new URLSearchParams();

  Object.entries(params).forEach(([k, v]) => {
    if (Array.isArray(v)) {
      v.forEach((vv) => query.append(k, vv));
    } else {
      if (v !== undefined && v !== null) {
        query.append(k, String(v));
      }
    }
  });
  return query;
};

export const prepareBodyWithFile = (args: Record<string, any>) => {
  const { upload, ...data } = args;
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      formData.append(key, value as unknown as Blob);
    }
  });
  upload.forEach((file: UploadFile) => {
    formData.append('files', file.originFileObj as unknown as Blob);
  });
  return formData;
};
export const prepareFilesForUpload = (files: { files: UploadFile[] }) => {
  const formData = new FormData();
  const filesArray = files.files;

  console.log('filesArrayэто:', filesArray);

  filesArray.forEach((file: UploadFile) => {
    formData.append('files', file as unknown as Blob);
  });

  return formData;
};

export const getAge = (date: string) =>
  dayjs().diff(date, 'year') !== 0 ? `${dayjs().diff(date, 'year')}` : `${dayjs().diff(date, 'month')}`;

export const filterDoctorsByAge = (
  doctors: Doctor[],
  showChildren?: boolean,
  childrenAge?: number,
  specialtyId?: string,
  speciality?: string,
) =>
  showChildren
    ? doctors.filter(
        ({ specialitiesTags }) =>
          specialitiesTags.filter(
            ({ serveChildren, serveChildrenFrom, serveChildrenTo, idFerSpeciality, specialityName }) =>
              serveChildren &&
              (!specialtyId || specialtyId === idFerSpeciality) &&
              (!speciality || specialityName.includes(speciality)) &&
              childrenAge !== undefined &&
              serveChildrenFrom <= childrenAge &&
              serveChildrenTo >= childrenAge,
          ).length > 0,
      )
    : doctors.filter(
        ({ specialitiesTags }) =>
          specialitiesTags.filter(
            ({ serveAdults, idFerSpeciality, specialityName }) =>
              (!speciality || specialityName.includes(speciality)) &&
              (!specialtyId || specialtyId === idFerSpeciality) &&
              serveAdults,
          ).length > 0,
      );

export const getNotificationTimeLabel = (date: string) =>
  `${dayjs(date).format('DD.MM.YYYY')} в ${dayjs(date).format('HH:mm')}`;

// eslint-disable-next-line @typescript-eslint/naming-convention
const NotificationsAppointmentTypes = [
  NotificationType.AppointmentCanceled,
  NotificationType.AppointmentSent,
  NotificationType.AppointmentRemind,
  NotificationType.AppointmentRescheduled,
  NotificationType.AppointmentReviewRequest,
  NotificationType.AppointmentChanged,
];

export const getNotificationTagName = (type: NotificationType) => {
  if (type === NotificationType.MedicalCardUpdate || type === NotificationType.MedicalCardShare) {
    return 'Медкарта';
  } else if (NotificationsAppointmentTypes.indexOf(type) !== -1) {
    return 'Записи';
  } else if (NotificationType.WaitingList) {
    return 'Лист ожидания';
  } else {
    return '';
  }
};

export const removeDuplicatesById = (arr: NotificationEntity[]) =>
  arr?.reduce((unique: NotificationEntity[], o: NotificationEntity) => {
    if (!unique.some((obj: NotificationEntity) => obj.idNotification === o.idNotification)) {
      unique.push(o);
    }
    return unique;
  }, []);

export const stringToHash = (guid: string) => {
  let hash = 0;

  if (guid?.length === 0) return hash;

  for (let i = 0; i < guid?.length; i++) {
    let char = guid.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};

export const getGuidBasedColor = (guid: string) => avatarColors[Math.abs(stringToHash(guid) % 8)];

export const getLinkNotification = (type: NotificationType, id?: string) => {
  if (NotificationsAppointmentTypes.indexOf(type) !== -1 && id) {
    return `/records/appointment/${id}`;
  } else if (type === NotificationType.MedicalCardUpdate && id) {
    return `/medcards/${id}`;
  } else return ''; // add other cases when all requirements is ready
};

export const getChildrenBirthDate = (date: string) => dayjs(date).format('DD.MM.YYYY');

export const scrollDown = () =>
  setTimeout(() => window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight }), 0);

export const linkEsiaAccount = async (keycloakObj: any) => {
  const url = `${
    window.__RUNTIME_CONFIG__.REACT_APP_ESIA_LINK_URL as string
  }?clientId=${clientId}&returnUrl=${redirectUrl}`;

  const config = {
    headers: {
      Authorization: `Bearer ${KeyCloakService(keycloakObj).getAuthToken()}`,
      accessToken: KeyCloakService(keycloakObj).getAccessToken(),
    },
  };

  const resp = await axios.get(url, config);
  window.location.href = await resp.data;
};

export const handleAddEsiaAccount = async (keycloakObj: any) => {
  await linkEsiaAccount(keycloakObj);
};

export const sortProfilesByItself = (profiles: Profile[]) =>
  profiles?.slice().sort((a: Profile, b: Profile) => Number(b.itSelf) - Number(a.itSelf));

export const getProfileNameLabel = (profile: Profile) =>
  `${profile.milaName ? profile.milaName : `${profile.name}`} ${profile.itSelf ? '(Я)' : ''}`;

export const getConsentDate = (date: Date | string) =>
  `${dayjs(date).format('D MMMM YYYY')} в ${dayjs(date).format('HH:mm')}`;

export const getPaymentStatus = (status: PaymentStatusType) => {
  if (status === PaymentStatusType.Paid) {
    return 'Оплачено';
  } else if (status === PaymentStatusType.Canceled) {
    return 'Списание';
  } else if (status === PaymentStatusType.PaymentSystemError) {
    return 'Ошибка';
  } else if (status === PaymentStatusType.Unpaid) {
    return 'Не оплачено';
  } else if (status === PaymentStatusType.Prepaid) {
    return 'Забронировано';
  } else {
    return 'Возврат';
  }
};

export const removePaymentsDuplicatesById = (arr: Payment[]) =>
  arr?.reduce((unique: Payment[], o: Payment) => {
    if (!unique.some((obj: Payment) => obj.id === o.id)) {
      unique.push(o);
    }
    return unique;
  }, []);

export const getProfileById = (profiles: Profile[], id: string) =>
  profiles?.find((profile) => profile.idProfile === id);

export const getShortenClinicName = (name: string) => (name?.length > 24 ? `${name.substring(0, 24)}...` : name);

export const getAppointmentClinicName = (clinic: string, type: string) => (type === 'Амбулаторный' ? clinic : type);

export const handlePatientsClick = () => {
  window.open(MILA_ONLINE_LINK, '_blank', 'noopener,noreferrer');
};

export const handleClinicsClick = () => {
  window.open(N3_HEALTH_PATIENTS_LINK, '_blank', 'noopener,noreferrer');
};

export const removeDuplicatesFromSpecialities = (arr: any) =>
  arr?.reduce((unique: AutocompleteOption[], o: AutocompleteOption) => {
    if (!unique.some((obj: AutocompleteOption) => obj.label === o.label)) {
      unique.push(o);
    }
    return unique;
  }, []);

export const removeCents = (price?: string) => price?.split('.')[0];

export const checkIsPaid = (appointmentPaymentStatus?: AppointmentPaymentStatus) =>
  appointmentPaymentStatus === AppointmentPaymentStatus.MoneyReserved ||
  appointmentPaymentStatus === AppointmentPaymentStatus.WithdrawByClinic ||
  appointmentPaymentStatus === AppointmentPaymentStatus.WithdrawByClinicWithoutReport ||
  appointmentPaymentStatus === AppointmentPaymentStatus.WithdrawByPatientInOrderOfCancellingAnAppointment ||
  appointmentPaymentStatus === AppointmentPaymentStatus.WithdrawBySystem ||
  appointmentPaymentStatus === AppointmentPaymentStatus.WithdrawByClinicInOrderOfSuccess;

export const getAppointmentStatus = (visitStart?: string, visitEnd?: string) => {
  const startDate = dayjs(visitStart);
  const endDate = dayjs(visitEnd);
  const currentDate = dayjs();
  const difference = startDate.diff(currentDate, 'minute');

  if (difference > -120 && difference < endDate.diff(startDate, 'minute')) {
    return AppointmentStatus.Ongoing;
  }
  if (difference > 0) {
    return AppointmentStatus.Upcoming;
  }
  if (difference < 0) {
    return AppointmentStatus.Past;
  }

  return AppointmentStatus.Unknown;
};

export const getAppointmentStatusByCreated = (created: string) => {
  const startDate = dayjs(created);
  const currentDate = dayjs();
  const difference = startDate.diff(currentDate, 'minute');

  if (difference <= 15 && difference > -15) {
    return AppointmentStatus.Ongoing;
  }
  if (difference > 15) {
    return AppointmentStatus.Upcoming;
  }
  if (difference < 0) {
    return AppointmentStatus.Past;
  }

  return AppointmentStatus.Unknown;
};

export const checkPaymentStatus = (paymentStatus: AppointmentPaymentStatus | undefined) => {
  const canceledPayment = paymentStatus === AppointmentPaymentStatus.Canceled;

  const refundedMoney =
    paymentStatus === AppointmentPaymentStatus.RefundedByClinic ||
    paymentStatus === AppointmentPaymentStatus.RefundedByPatient ||
    paymentStatus === AppointmentPaymentStatus.RefundedBySystem;

  return {
    canceledPayment,
    refundedMoney,
  };
};
