import { BackHeader } from 'src/common/components/BackHeader/BackHeader';
import { BranchPlacemark } from '../MapModule/BranchPlacemark/BranchPlacemark';
import { Calendar } from 'src/common/icons';
import { ClinicPromoCard } from './ClinicPromoCard/ClinicPromoCard';
import { Col, Image, Row, Typography } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { DoctorCard } from '../DoctorChoice/DoctorCard/DoctorCard';
import { MapWithPlacemarks } from '../../common/components/MapWithPlacemarks/MapWithPlacemarks';
import { getPromotionDateLabel } from '../../common/utils';
import {
  useGetClinicsQuery,
  useGetDoctorsQuery,
  useGetPromotionInfoQuery,
} from '../../common/redux/api/apiPatientSlice';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import {DisplayType} from "../../common/types";

const items = [
  {
    label: 'Списком',
    key: '1',
  },
  {
    label: 'На карте',
    key: '2',
  },
];

export const Promo = () => {
  const { promoId } = useParams();
  const { data: promoInfo, isLoading: isLoadingPromo } = useGetPromotionInfoQuery(promoId!);
  const navigate = useNavigate();

  const { data: doctors, isLoading } = useGetDoctorsQuery({ promotionId: promoId });

  // Consider refactoring to remove duplicated data
  const { data: participateClinics, isLoading: isLoadingClinics } = useGetClinicsQuery({
    promotionId: promoId!,
  });

  const [activeTab, setActiveTab] = useState('1');
  const [placemark, setPlacemark] = useState();

  const handleChangeClinicTab = (tab: string) => {
    setActiveTab(tab);
  };

  const lifetime = useMemo(
    () =>
      (promoInfo?.isPromotionInfinite && !promoInfo?.endTimestamp) || !promoInfo?.endTimestamp
        ? 'Постоянная акция'
        : getPromotionDateLabel(promoInfo?.startTimestamp, promoInfo?.endTimestamp),
    [promoInfo?.startTimestamp, promoInfo?.endTimestamp, promoInfo?.isPromotionInfinite],
  );

  const handleMapClick = useCallback(
    (e: Event, branchId: string) => {
      e.preventDefault();
      navigate(`/catalog/${branchId}`);
    },
    [navigate],
  );
  
  const openLink = () => {
    window.open(promoInfo?.buttonLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      <BackHeader title="Акция" />
      <Row className={styles.PromoRow}>
        <Typography className={styles.Title}>{promoInfo?.title}</Typography>
        <Row className={styles.DateRow}>
          <Calendar />
          <Typography>{lifetime}</Typography>
        </Row>
        <Row className={styles.MainRow}>
          <Col className={styles.PromoCol}>
            <Row className={styles.PictureLogoWrapper}>
              <Image
                preview={false}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${promoInfo?.backgroundImageStaticFileId}`}
                className={styles.NetworkBanner}
              />

              {promoInfo?.networkLogoStaticFileId && <Image
                preview={false}
                src={`${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${promoInfo?.networkLogoStaticFileId}`}
                className={styles.NetworkLogo}
              />}
            </Row>
            <Typography className={styles.PaleText}>{promoInfo?.subtitle}</Typography>
          </Col>
          <Col className={styles.PromoCol}>
            <Typography dangerouslySetInnerHTML={{ __html: promoInfo?.description! }} />
            <CustomButton onClick={() => promoInfo?.buttonLink && openLink()} className={styles.Button}>
              <Typography>{promoInfo?.buttonText}</Typography>
            </CustomButton>
          </Col>
        </Row>
      </Row>

      {promoInfo?.displayType !== DisplayType.DontShow && <>
      <Typography className={styles.Title}>Действует в клиниках</Typography>
      <CustomCardTabs items={items} onChange={handleChangeClinicTab} />
      {activeTab === '1' ? (
        isLoadingClinics || isLoadingPromo ? (
          <CustomSpinner />
        ) : (
          participateClinics?.map((branch) => <ClinicPromoCard key={branch.idMedOrganization} clinic={branch} />)
        )
      ) : (
        <MapWithPlacemarks
          mapState={{
            zoom: 15,
            center:
              participateClinics && participateClinics?.length > 0
                ? [Number(participateClinics[0]?.latitude), Number(participateClinics[0]?.longitude)]
                : [0, 0],
          }}
          placemark={placemark}
        >
          {participateClinics?.map((branch) => (
            <BranchPlacemark
              latitude={branch.latitude}
              longitude={branch.longitude}
              key={branch.idMedOrganization}
              branch={branch}
              handleClick={handleMapClick}
              instanceRef={setPlacemark}
            />
          ))}
        </MapWithPlacemarks>
      )}</>}

      <Typography className={styles.Title}>Участвуют врачи</Typography>
      <Row className={styles.DoctorsRow}>
        {isLoading ? (
          <CustomSpinner />
        ) : (
          doctors?.map((doctor) => <DoctorCard key={doctor.idMilaDoctor} doctor={doctor} />)
        )}
      </Row>
    </>
  );
};
