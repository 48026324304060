import { ButtonsBlock } from './ButtonBlock/ButtonsBlock';
import { CustomModal } from '../CustomModal/CustomModal';
import { Image, Row, Typography, notification } from 'antd';
import { MilaMessage } from '../MilaMessage/MilaMessage';
import { setRescheduleAppointmentId } from 'src/common/redux/appointment/appointmentSlice';
import { useAppDispatch } from 'src/app/hooks';
import { useCancelAppointmentMutation } from '../../redux/api/apiPatientSlice';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import RecordCancelled from 'src/assets/RecordCancelled.svg';
import styles from './styles.module.scss';

export interface CancelAppointmentProps {
  appointmentId: string;
  doctorId: string;
  isCancelModalVisible: boolean;
  handleSetCancelModalVisibility: (isVisible: boolean) => void;
  isTmk: boolean;
  isLessThenTwoHours?: boolean;
  price?: string | null
}

export const CancelAppointmentModals = ({
  appointmentId,
  doctorId,
  isCancelModalVisible,
  handleSetCancelModalVisibility,
  isTmk,
  isLessThenTwoHours = false,
  price
}: CancelAppointmentProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cancelAppointment] = useCancelAppointmentMutation();

  const [isRecordCancelNotificationModalVisible, setRecordCancelNotificationModal] = useState(false);
  const [isConfirmationRescheduleAppointmentModalVisible, setConfirmationRescheduleAppointmentModalVisibility] =
    useState(false);

  const handleCancelRecordModalOk = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    handleSetCancelModalVisibility(false);
  };

  const closeCancelRecordModals = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    handleSetCancelModalVisibility(false);
    setConfirmationRescheduleAppointmentModalVisibility(false);
    setRecordCancelNotificationModal(false);
  };

  const onConfirmCancelClick = () => {
    cancelAppointment({ appointmentId })
      .unwrap()
      .then(() => {
        handleSetCancelModalVisibility(false);
        setRecordCancelNotificationModal(true);
      })
      .catch(() => {
        notification.error({ message: `Ошибка. Запись не отменена. Пожалуйста, попробуйте позже` });
      });
  };

  const handleRescheduleRecordClick = (e?: React.MouseEvent) => {
    e && e.stopPropagation();
    handleSetCancelModalVisibility(false);
    setConfirmationRescheduleAppointmentModalVisibility(true);
  };

  const onConfirmRescheduleClick = () => {
    dispatch(setRescheduleAppointmentId(appointmentId));
    navigate(`/records/aboutDoctor/${doctorId}?activeTab=schedule&reschedule=true`);
  };

  return (
    <>
      <CustomModal
        isOpen={(isCancelModalVisible && !isTmk) || (isCancelModalVisible && isTmk && !isLessThenTwoHours)}
        onOkClick={handleCancelRecordModalOk}
        onCancelClick={handleCancelRecordModalOk}
        className={styles.ModalContainer}
      >
        <Row className={styles.CancelRecordWrapper}>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage title={'Точно отменить?'} text={'Может быть все-таки перенести?'} />
          </Row>
          <ButtonsBlock
            buttonOneText="Да, отменить"
            buttonOneOnClick={onConfirmCancelClick}
            buttonTwoText="Перенести"
            buttonTwoOnClick={handleRescheduleRecordClick}
            buttonThreeText="Не отменять"
            buttonThreeOnClick={closeCancelRecordModals}
          />
        </Row>
      </CustomModal>
      <CustomModal
        isOpen={isCancelModalVisible && isTmk && isLessThenTwoHours}
        onCancelClick={handleCancelRecordModalOk}
        className={styles.ModalContainer}
      >
        <Row className={styles.CancelRecordWrapper}>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage
              title={'Точно отменить?'}
              text={
                price === '0' ? '' : 'К сожалению, мы не сможем вернуть вам деньги за предоплаченную онлайн-консультацию при ее отмене менее, чем за 2 часа до начала. Может быть все-таки успеете? Вам необязательно быть у компьютера, достаточно мобильного телефона и интернета.'
              }
            />
          </Row>
          <ButtonsBlock
            buttonOneText="Да, отменить"
            buttonOneOnClick={onConfirmCancelClick}
            buttonTwoText="Не отменять"
            buttonTwoOnClick={closeCancelRecordModals}
          />
        </Row>
      </CustomModal>
      <CustomModal
        isOpen={isRecordCancelNotificationModalVisible}
        onCancelClick={closeCancelRecordModals}
        className={styles.ModalContainer}
      >
        <Row className={styles.CancelRecordWrapper}>
          <Row>
            <Typography className={styles.CancelRecordConfirmed}>Запись отменена</Typography>
          </Row>
          <Row className={styles.PictWrapper}>
            <Image src={RecordCancelled} preview={false} className={styles.pict} alt="" />
          </Row>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage
              title={'Ваша запись отменена'}
              text={'Но, пожалуйста, не откладывайте нужные обследования надолго. Буду вас ждать!'}
            />
          </Row>
          <ButtonsBlock buttonOneText="Хорошо" buttonOneOnClick={closeCancelRecordModals} />
        </Row>
      </CustomModal>
      <CustomModal
        isOpen={isConfirmationRescheduleAppointmentModalVisible}
        className={styles.ModalContainer}
        onCancelClick={closeCancelRecordModals}
      >
        <Row className={styles.CancelRecordWrapper}>
          <Row className={styles.MilaMessageWrapper}>
            <MilaMessage
              title={'Переносим запись на новое время?'}
              text={
                'Данные о пациенте, информация и доступы для врача будут перенесены в новую запись, старая будет удалена. Если вы оплачивали консультацию онлайн - деньги будут возвращены на вашу карту, а новую запись потребуется оплатить заново.'
              }
            />
          </Row>
          <ButtonsBlock
            buttonOneText="Да, перенести"
            buttonOneOnClick={onConfirmRescheduleClick}
            buttonTwoText="Отмена"
            buttonTwoOnClick={closeCancelRecordModals}
          />
        </Row>
      </CustomModal>
    </>
  );
};
