import { Button, Image, Row, Typography } from 'antd';
import { CustomModal } from '../../../common/components/CustomModal/CustomModal';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { CustomTabs } from 'src/common/components/CustomTabs/CustomTabs';
import { ExtendedWhiteBlock } from 'src/features/Catalog/ExtendedWhiteBlock/ExtendedWhiteBlock';
import { InvitationModal } from '../InvitationModal/InvitationModal';
import { MilaMessage } from '../../../common/components/MilaMessage/MilaMessage';
import { PromoBlock } from 'src/common/components/PromoBlock/PromoBlock';
import { ServiceRequestType } from 'src/common/types/enums';
import { WhiteRow } from 'src/common/components/WhiteRow/WhiteRow';
import { useAppSelector } from 'src/app/hooks';
import {
  useGetPromotionsQuery,
  useGetSpecialtiesQuery
} from 'src/common/redux/api/apiPatientSlice';
import {
  useGetWhiteLabelInfoQuery
} from 'src/common/redux/api/apiWhiteLabelSlice';
import AddNewButton from '../../../assets/AddNewButton.svg';
import NoDoctors from 'src/assets/NoDoctors.png';
import React, { useMemo, useState } from 'react';
import { adultAge } from '../../../common/contants/constatns';
import styles from './styles.module.scss';
import {MilaService} from '../../../common/types';

const items = [
  { label: `В клинике`, key: ServiceRequestType.OnSite as unknown as string },
  { label: `Онлайн`, key: ServiceRequestType.Online as unknown as string },
  { label: `На дому`, key: ServiceRequestType.AtHome as unknown as string },
];

export const DoctorCatalog = () => {
  const [activeTab, setActiveTab] = useState(ServiceRequestType.OnSite);
  const region = useAppSelector((state) => state.filter.region);
  const showChildren = useAppSelector((state) => state.filter.isChildren);
  const childrenAge = useAppSelector((state) => state.filter.childrenAge);
  const { data: specialties, isFetching } = useGetSpecialtiesQuery({
    serviceType: activeTab,
    region: region,
    serveAge: showChildren ? childrenAge : adultAge
  });
  const { data: promotionsData, isLoading } = useGetPromotionsQuery({ region: region });
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab as unknown as ServiceRequestType);
  };

  return (
    <>
      <CustomTabs
        className={styles.TypeTabs}
        items={items}
        onChange={handleTabChange}
        containerClassName={styles.Tabs}
      />
      {isFetching ? (
        <CustomSpinner />
      ) : <>
        <ExtendedWhiteBlock specialties={specialties} isFetching={isFetching} serviceType={activeTab} />
        {specialties?.length === 0 && <div className={styles.NoDoctors}>
          <Image src={NoDoctors} className={styles.NoDoctorsImage} preview={false} />
          <Typography className={styles.NoDoctorsTitle}>В вашем регионе пока нет врачей</Typography>
          <Typography className={styles.NoDoctorsText}>Но скоро они будут добавлены.</Typography>
          {!whiteLabel?.isServiceEnabled &&
            <Typography className={styles.NoDoctorsText}>
              А еще вы можете пригласить свою клинику и врачей в Mila
            </Typography>
          }
          {(!whiteLabel?.isServiceEnabled || whiteLabel?.services?.includes(MilaService.AddClinic)) && <Row>
            <Button
              onClick={handleOpenModal}
              className={styles.AddClinicButton}
              icon={<Image src={AddNewButton} preview={false} />}
            >
              Пригласить клинику
            </Button>
          </Row>}
        </div>}
      </>}

      <Typography className={styles.Title}>Акции</Typography>

      {isLoading ? (
        <CustomSpinner />
      ) : promotionsData && promotionsData.length > 0 ? (
        <WhiteRow className={styles.PromoBlockWrapper}>
          <PromoBlock promotions={promotionsData!} />
        </WhiteRow>
      ) : (
        <MilaMessage
          title="Здесь будут акции клиник"
          text={`Вы сможете узнавать о специальных предложениях, программах лояльности и скидках${!whiteLabel?.isServiceEnabled ? ' в клиниках-партнерах Mila' : ''}  `}
        />
      )}

      <CustomModal isOpen={isModalOpen} className={styles.AddClinicModal} onCancelClick={handleCloseModal}>
        <InvitationModal />
      </CustomModal>
    </>
  );
};
