import { GreenCheckIcon } from '../../icons';
import { Tag } from 'antd';
import styles from './styles.module.scss';

interface PriceTagProps {
  appointmentPaymentStatusName?: string;
  isPaid?: boolean;
  paymentType?: string;
  price?: string;
}

export const PriceTag = ({ appointmentPaymentStatusName, isPaid, paymentType, price }: PriceTagProps) => {
  return (
    <Tag
      className={isPaid || price === '0' ? styles.PaidPrice : paymentType === 'онлайн' ? styles.WaitingPaymentPrice : styles.Price}
      icon={isPaid || price === '0' ? <GreenCheckIcon className={styles.TagIcon} /> : null}
    >
      {price === '0' ? 'Бесплатно' : `${paymentType === 'онлайн' ? appointmentPaymentStatusName : 'Оплата в клинике'} ${price} Р`}
    </Tag>
  );
};
