import { Analysis } from '../Analysis/Analysis';
import { Avatar } from 'src/common/components/Avatar/Avatar';
import {Col, notification, Row, Typography} from 'antd';
import { CustomCardTabs } from 'src/common/components/CustomCardTabs/CustomCardTabs';
import { CustomSpinner } from 'src/common/components/CustomSpinner/CustomSpinner';
import { Gender } from 'src/common/types/enums';
import { MedCardDocuments } from '../MedCardDocuments/MedCardDocuments';
import {
  useGetSharedMedcardCalendarQuery,
  useGetSharedMedcardCasesQuery,
  useGetSharedMedcardDocumentsQuery,
  useGetSharedMedcardInfoQuery,
} from 'src/common/redux/api/apiSharedSlice';
import { useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import Slider from 'react-slick';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

export const SharedMedcard = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState<string>('2');

  const { data: info, isFetching: infoFetching, error } = useGetSharedMedcardInfoQuery(id!, {
    skip: !id,
  });

  const { data: calendarData, isFetching: calendarFetching } = useGetSharedMedcardCalendarQuery(id!, {
    skip: !id,
  });

  const { data: calendarCases, isFetching: calendarCasesFetching } = useGetSharedMedcardCasesQuery(id!, {
    skip: !id,
  });

  const { data: documents, isFetching: documentsFetching } = useGetSharedMedcardDocumentsQuery(id!, {
    skip: !id,
  });

  useEffect(() => {
    //if (info?.hideSmartAnalysisTab) {
      setActiveTab('2');
    // } else {
    //   setActiveTab('1');
    // }
  }, [info]);

  useEffect(() => {
    if (error) {
      notification.error({message: 'Просмотр медкарты ограничен'});
    }
  }, [error]);

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const items = useMemo(
    () => [{ label: `Документы`, key: '2' }],
    [],
  );

  return (
    <>
      <Typography className={styles.Title}>Медкарта</Typography>
      {infoFetching ? (
        <CustomSpinner />
      ) : (
        <Row className={styles.UserRow}>
          {info && (
            <Avatar
              idPhoto={''}
              className={styles.Avatar}
              idProfile={info?.idProfile}
              name={info?.fio}
              classNameText={styles.FioText}
            />
          )}
          <Col className={styles.NameCol}>
            {info?.fio && <Typography>{info?.fio}</Typography>}
            {info?.gender && info?.age && info?.birthday && (
              <Typography className={styles.GreySmallText}>{`${info?.gender === Gender.Male ? 'Мужчина' : 'Женщина'}, ${
                +info?.age > 0 ? info?.age : ''
              } (${dayjs(info?.birthday).format('D MMM YYYY')})`}</Typography>
            )}
          </Col>
        </Row>
      )}
      {/* {info?.hideSmartAnalysisTab === false && ( */}
      {items.length > 1 &&
        <Slider>
          <CustomCardTabs items={items} onChange={handleTabChange} containerClassName={styles.TabsContainer} />
        </Slider>
      }

      {activeTab === '1' && (
        <Analysis
          calendarCases={calendarCases}
          calendarCasesFetching={calendarCasesFetching}
          calendarData={calendarData}
          calendarFetching={calendarFetching}
          idShared={id}
        />
      )}
      {activeTab === '2' &&
        id &&
        (documentsFetching ? <CustomSpinner /> : <MedCardDocuments showFilters idShared={id} documents={documents} />)}
    </>
  );
};
