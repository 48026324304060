import { AgreementModal } from '../../common/components/AgreementModal/AgreementModal';
import { BackHeader } from '../../common/components/BackHeader/BackHeader';
import { Col, Row, Typography } from 'antd';
import { CustomSpinner } from '../../common/components/CustomSpinner/CustomSpinner';
import { CustomSwitch } from '../../common/components/CustomSwitch/CustomSwitch';
import { MilaMessage } from '../../common/components/MilaMessage/MilaMessage';
import { ProfileTabs } from '../../common/components/ProfileTabs/ProfileTabs';
import { WhiteRow } from '../../common/components/WhiteRow/WhiteRow';
import { getConsentDate } from '../../common/utils';
import {
  idMedicalDataAccessConsent,
  idNotificationsAgreementConsent,
  idPersonalDataConsent,
  idProcessPersonalDataAgreementConsent,
} from '../../common/contants/constatns';
import {
  useGetConsentsQuery,
  useGetProfilesQuery,
  useUpdateConsentMutation,
} from 'src/common/redux/api/apiPatientSlice';
import { useGetWhiteLabelInfoQuery } from 'src/common/redux/api/apiWhiteLabelSlice';
import { useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

export const Consent = () => {
  const { data: whiteLabel } = useGetWhiteLabelInfoQuery('');
  const isWhiteLabelEnabled = useMemo(() => {
    return whiteLabel?.isServiceEnabled;
  }, [whiteLabel]);

  const { data: profiles, isLoading: isLoadingProfiles } = useGetProfilesQuery();
  const [updateConsent] = useUpdateConsentMutation();
  const idProfile = useMemo(() => profiles?.find((profile) => profile.itSelf)?.idProfile || '', [profiles]);
  const [selectedPerson, setSelectedPerson] = useState<string>('');
  const [params, setSearchParams] = useSearchParams();
  const preselectedPersonTab = useMemo(() => params.get('profileId'), [params]);

  const { data: consents, isFetching, refetch } = useGetConsentsQuery(selectedPerson, { skip: !selectedPerson });
  const handleUpdateConsent = (idMilaConsent: string, isActive: boolean) => () =>
    updateConsent({ idMilaConsent, idProfile: selectedPerson, isActive }).then(() => refetch());

  const [isAgreementModalOpen, setAgreementModalOpen] = useState(false);
  const [openedConsentId, setOpenedConsentId] = useState('');

  const handleOpenConsentModal = (consentId: string) => {
    if (isWhiteLabelEnabled) {
      switch (consentId) {
        case idPersonalDataConsent:
          if (whiteLabel?.idPersonalDataAgreementFile) {
            return handleAgreementDownload(whiteLabel?.idPersonalDataAgreementFile);
          }
          break;
        case idMedicalDataAccessConsent:
          if (whiteLabel?.idMedCardAgreementFile) {
            return handleAgreementDownload(whiteLabel?.idMedCardAgreementFile);
          }
          break;
        case idNotificationsAgreementConsent:
          if (whiteLabel?.idNotificationAgreementFile) {
            return handleAgreementDownload(whiteLabel?.idNotificationAgreementFile);
          }
          break;
      }
    }
    setAgreementModalOpen(true);
    setOpenedConsentId(consentId);
  };

  const handleAgreementDownload = (fileId?: string) => {
    if (fileId) {
      window.open(
        `${window.__RUNTIME_CONFIG__.REACT_APP_STATIC_FILES}/${fileId}?inline=false`,
        '_blank',
        'noopener,noreferrer',
      );
    }
  };

  useEffect(() => {
    if (!selectedPerson) {
      setSelectedPerson(idProfile);
    }
  }, [idProfile, selectedPerson]);

  useEffect(() => {
    if (preselectedPersonTab) {
      setSelectedPerson(preselectedPersonTab);
    }
  }, [preselectedPersonTab]);

  const handleCloseModal = () => {
    setAgreementModalOpen(false);
  };

  const handlePersonTabClick = (key: string) => {
    setSearchParams({ profileId: key });
  };

  const hiddenText = isWhiteLabelEnabled ? (
    <>
      <Typography.Text>
        Чтобы видеть медицинские данные и получать уведомления, вам необходимо подтвердить ваши согласия. Согласно
        Федеральному закону "О персональных данных" от 27.07.2006 N 152-ФЗ и на основании статьи 22 Федерального закона
        "Об основах охраны здоровья граждан в Российской Федерации" от 21. 11.2011 N 323-ФЗ вы можете согласиться или
        отказаться от получения уведомлений и от просмотра медицинских сведений.
      </Typography.Text>
      <Typography.Text>
        В случае отмены согласия вам не будет доступен просмотр медицинских документов в электронном виде в Личном
        кабинете (результаты анализов, случаи медицинского обслуживания), не будут отправляться уведомления.
      </Typography.Text>
      <Typography.Text>
        Согласие на просмотр медицинских сведений действует отдельно для каждого подключенного профиля.
      </Typography.Text>
    </>
  ) : (
    <>
      <Typography.Text>
        Тут все строго. Чтобы видеть медицинские данные и получать уведомления, вам необходимо подтвердить ваши согласия
      </Typography.Text>
      <Typography.Text>
        Согласно Федеральному закону "О персональных данных" от 27.07.2006 N 152-ФЗ и на основании статьи 22
        Федерального закона "Об основах охраны здоровья граждан в Российской Федерации" от 21. 11.2011 N 323-ФЗ Вы
        можете согласиться или отказаться от получения уведомлений и от просмотра медицинских сведений.
      </Typography.Text>
      <Typography.Text>
        В случае отмены согласия вам не будет доступен просмотр медицинских документов в электронном виде в личном
        кабинете в Mila (результаты анализов, случаи медицинского обслуживания), не будут отправляться уведомления от
        Mila.
      </Typography.Text>
      <Typography.Text>
        Согласие на просмотр медицинских сведений действует отдельно для каждого подключенного профиля.
      </Typography.Text>
    </>
  );

  const text = isWhiteLabelEnabled
    ? 'Чтобы видеть медицинские данные и получать уведомления, вам необходимо подтвердить ваши согласия.'
    : 'Тут все строго. Получать медицинские данные, обеспечивать к ним доступ и присылать уведомления могу только с вашего согласия.';

  return (
    <>
      <BackHeader title="Согласия на доступ к данным" />
      <Row className={styles.ConsentsRow}>
        {isLoadingProfiles ? (
          <CustomSpinner />
        ) : (
          <ProfileTabs activeProfile={selectedPerson} onChange={handlePersonTabClick} withoutAllTab />
        )}
      </Row>
      <MilaMessage
        title={
          isWhiteLabelEnabled
            ? 'Для работы сервисов Личного кабинета необходимы согласия'
            : 'Без ваших согласий совсем ничего не смогу вам рассказать'
        }
        text={text}
        hiddenText={hiddenText}
        className={styles.MilaMessageWrapper}
      />
      <WhiteRow className={styles.ConsentsRow}>
        {isFetching ? (
          <CustomSpinner />
        ) : (
          consents?.map(({ text, idMilaConsent, isActive, signed, revoked }) => (
            <Row
              className={`${styles.Consent} ${
                idProfile !== selectedPerson &&
                (idNotificationsAgreementConsent === idMilaConsent ||
                  idProcessPersonalDataAgreementConsent === idMilaConsent) &&
                styles.HiddenConsent
              }`}
              key={idMilaConsent}
            >
              <Col className={styles.ConsentTextBlock}>
                <Typography.Title level={3}>{text}</Typography.Title>
                {signed && (
                  <Typography.Text className={styles.ConsentDate}>{`${
                    isActive ? `Подписано: ${getConsentDate(signed)}` : `Отозвано: ${getConsentDate(revoked)}`
                  }`}</Typography.Text>
                )}
                <Typography.Link target="_blank" onClick={() => handleOpenConsentModal(idMilaConsent)}>
                  Посмотреть
                </Typography.Link>
              </Col>
              <Col>
                <CustomSwitch
                  text="Подписать"
                  checkedText="Подписано"
                  checked={isActive}
                  onChange={handleUpdateConsent(idMilaConsent, !isActive)}
                  disabled={idProfile === selectedPerson && isActive && idMilaConsent === idPersonalDataConsent}
                />
              </Col>
              <AgreementModal
                consentId={idMilaConsent}
                isModalOpen={isAgreementModalOpen && openedConsentId === idMilaConsent}
                handleCloseModal={handleCloseModal}
              />
            </Row>
          ))
        )}
      </WhiteRow>
    </>
  );
};
