import { Checkbox, Col, Form, Row, Typography, notification } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CustomButton } from '../../common/components/CustomButton/CustomButton';
import { CustomInput } from '../../common/components/CustomInput/CustomInput';
import { SubmitModal } from './SubmitModal';
import { WhiteRow } from '../../common/components/WhiteRow/WhiteRow';
import { useForm } from 'antd/es/form/Form';
import { useSendMessageToSupportMutation } from '../../common/redux/api/apiPatientSlice';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line css-modules/no-unused-class
import styles from './styles.module.scss';

interface FormType {
  customFields: string[];
  customFieldsValues: {
    [key: string]: string;
  };
  setCustomFieldsValues: (values: { [key: string]: string }) => void;
  idProfile: string;
}

type FormFields = {
  subject: string;
  message: string;
  middleName: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const SupportForm = ({ customFields, customFieldsValues, setCustomFieldsValues, idProfile }: FormType) => {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true);

  const handleCloseModal = () => setIsModalOpen(false);

  const [sendMessage] = useSendMessageToSupportMutation();

  const handleSubmitDisabled = (e: CheckboxChangeEvent) => setSubmitDisabled(!e.target.checked);

  useEffect(() => {
    form.setFieldsValue(customFieldsValues);
  }, [customFieldsValues, form]);

  const handleValuesChange = () => setCustomFieldsValues(form.getFieldsValue(customFields));

  const handleFormSubmit = (values: FormFields) => {
    sendMessage({ ...values, idMedOrganization: '', idProfile })
      .unwrap()
      .then(() => {
        form.resetFields();
        notification.success({ message: 'Обращение успешно отправлено' });
      })
      .catch(() => {
        notification.error({ message: 'Ошибка отправления сообщения' });
      });
  };

  return (
    <>
      <WhiteRow className={styles.WhiteCol}>
        <Typography.Title level={3} className={styles.Title}>
          Написать в службу техподдержки
        </Typography.Title>
        <Form form={form} className={styles.Form} onValuesChange={handleValuesChange} onFinish={handleFormSubmit}>
          <Row>
            <Col>
              <Form.Item rules={[
                {
                  required: true,
                  message: 'Пожалуйста введите тему',
                },
              ]} name="subject">
                <CustomInput floatLabel="Тема *" floatLabelClassName={styles.FloatLabel} />
              </Form.Item>
              <Form.Item name="message" rules={[
                {
                  required: true,
                  message: 'Пожалуйста введите сообщение',
                },
              ]} className={styles.TextArea}>
                <CustomInput
                  floatLabel="Сообщение *"
                  isTextArea
                  floatLabelClassName={`${styles.FloatLabel} ${styles.TextArea}`}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите фамилию',
                  },
                ]}
              >
                <CustomInput floatLabel="Фамилия *" className={`${styles.FloatLabel} ${styles.FloatLabelData}`} />
              </Form.Item>
              <Row>
                <Form.Item
                  name="firstName"
                  rules={[
                    {
                      required: true,
                      message: 'Пожалуйста введите имя',
                    },
                  ]}
                >
                  <CustomInput floatLabel="Имя *" className={`${styles.FloatLabel} ${styles.FloatLabelData}`} />
                </Form.Item>
                <Form.Item name="middleName">
                  <CustomInput
                    floatLabel="Отчество пациента"
                    className={`${styles.FloatLabel} ${styles.FloatLabelData}`}
                  />
                </Form.Item>
              </Row>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста введите корректную электронную почту',
                    pattern: /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/,
                  },
                ]}
              >
                <CustomInput floatLabel="E-mail для получения ответа *" className={`${styles.FloatLabel} ${styles.FloatLabelData}`} />
              </Form.Item>
            </Col>
          </Row>
          <Row className={styles.BottomAlign}>
            <Col>
              <Checkbox className={styles.Agree} onChange={handleSubmitDisabled}>
                Даю согласие на обработку своих персональных данных (имя, отчество, фамилия, телефон, почта) в
                соответствии с требованиям ФЗ №152 от 27.07.2006
              </Checkbox>
            </Col>
            <Col>
              <CustomButton htmlType="submit" isPrimal className={styles.Button} disabled={isSubmitDisabled}>
                <Typography.Text>Отправить</Typography.Text>
              </CustomButton>
            </Col>
          </Row>
        </Form>
      </WhiteRow>
      <SubmitModal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} />
    </>
  );
};
