import { Avatar } from 'src/common/components/Avatar/Avatar';
import { Col, Row, Typography, notification } from 'antd';
import { CustomButton } from 'src/common/components/CustomButton/CustomButton';
import { CustomTag } from 'src/common/components/CustomTag/CustomTag';
import { Link } from 'react-router-dom';
import { MedcardLink } from 'src/common/types';
import { useDeleteSharedLinkMutation } from 'src/common/redux/api/apiPatientSlice';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface SharedLinkProps {
  link: MedcardLink;
}

export const SharedLink = ({ link }: SharedLinkProps) => {
  const [deleteLink] = useDeleteSharedLinkMutation();

  const handleCopy = () => {
    const address =
        window.__RUNTIME_CONFIG__.REACT_APP_HOST.slice(-1) === '/'
            ? window.__RUNTIME_CONFIG__.REACT_APP_HOST.slice(0, -1)
            : window.__RUNTIME_CONFIG__.REACT_APP_HOST;
    navigator.clipboard.writeText(`${address}/shared/medcard/${link.link}`);
    notification.success({ message: 'Ссылка скопирована в буфер обмена' });
  };
  const handleDelete = () => {
    deleteLink(link.idMilaSharedLink).unwrap().then((res: any) => {
      notification.success({message: 'Ссылка удалена'});
    });
  };

  return (
    <Row className={styles.SharedLinkWrapper}>
      <Row className={styles.TextRow}>
        <Col>
          <Avatar
            className={styles.Avatar}
            idPhoto={link.milaUserProfile.idPhoto}
            idProfile={link.milaUserProfile.idMilaUserProfile}
            name={`${link.milaUserProfile.lastName} ${link.milaUserProfile.firstName} ${link.milaUserProfile.middleName}`}
          />
        </Col>
        <Col>
          <Typography className={styles.DateText}>
            Доступ от {dayjs(link.created).format('DD.MM.YYYY, HH:mm')}
          </Typography>
          <Typography className={styles.ExpireText}>
            Действует до {dayjs(link.expires).format('DD.MM.YYYY, HH:mm')}
          </Typography>
        </Col>
        <Col className={styles.accessTag}>
          <CustomTag>{link.type === 0 ? 'Доступ для врача' : 'Свободный доступ'}</CustomTag>
        </Col>
      </Row>
      <Link to={`/shared/medcard/${link.link}`} className={`${styles.LinkText} ${link.disabledLink ? styles.LinkDisabled : ''}`} target="_blank">
        Посмотреть вид медкарты по ссылке
      </Link>
      <Row className={styles.ButtonRow}>
        <CustomButton disabled={link.disabledLink} className={styles.Button} onClick={handleCopy}>
          <Typography className={styles.ButtonText}>Скопировать ссылку</Typography>
        </CustomButton>
        <CustomButton className={styles.Button} onClick={handleDelete}>
          <Typography className={styles.ButtonText}>Закрыть доступ и удалить ссылку</Typography>
        </CustomButton>
      </Row>
    </Row>
  );
};
